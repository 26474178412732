import config from "../config";

export function validateCorreiosPassword(matricula, password){
  // if (config.url === 'https://lass-api.stoom.com.br'){
    // return password === generatePassword(matricula)
  // } else {
    return password === '1B2C'
  // }
}

function generatePassword(matricula) {
const caracter = ['B', 'C', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'V', 'W', 'X', 'Y', 'Z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  let numero = 0;
  const data = new Date();
  const dia = data.getDate();
  const mes = data.getMonth() + 1; // getMonth() retorna 0-11, então somamos 1
  const ano = data.getFullYear() - 2000;

  const numDig = caracter.length;
  let posCod = 0;
  let senha = "";

  posCod = 0;
  for (let i = 6; i >= 0; i -= 2) {
      numero = parseInt(matricula.substring(i, i + 2), 10);

      numero = Math.sqrt(((numero + dia) / mes) * ano);
      numero = Math.floor((numero - Math.floor(numero)) * 100);
      numero = numero - (Math.floor(numero / numDig) * numDig);

      posCod = posCod + Math.floor(numero);
      posCod = posCod - Math.floor(posCod / numDig) * numDig;

      senha += caracter[posCod];
  }

  return senha;
}