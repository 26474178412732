/* eslint-disable */

/*
    Config: Configurações para teste local, stating e  Prod.
    Por fazer conexão com backend e com o Locker (local) é necessário dois ambientes para cada configuração;
    As configurações:

    prod: Backend Prod e Locker Prod. Test is false.

    staging: Backend STAGING e Locker Prod.
    stagingLocal: Backend STAGING e Mock LOCAL do Locker.

    local: Backend LOCAL e Mock LOCAL do Locker.
*/


// SERVER: STAGING
// LOCKER: PROD.

const config = {
  // PRD
  // url: "https://lass-api.stoom.com.br",
  // urllocker: "http://localhost:8005",
  // urllocaldb: "http://localhost:8015",

  // QA
  url: "https://lass-api.dev.stoomlab.com.br",
  urllocker: "https://lass-laurenti-api.dev.stoomlab.com.br",
  urllocaldb: "https://locker-local-db.dev.stoomlab.com.br",
  secretlocaldb: "dlGzZG9j4kmmBUGK6cn92z50gufaldHorJEv8RcdRP4=",


  // HML
  // url: "https://lass-api.dev.stoomlab.com.br",
  // urllocker: "http://localhost:8005",
  // urllocaldb: "http://localhost:8015",
  // secretlocaldb: "VpB_N1qk1N9L-QcUNojW0fjaOhUScArp2UPt7xGz-XA=",

  // LOCAL
  // url: "http://localhost:8000",
  // urllocker: "https://lass-laurenti-api.dev.stoomlab.com.br",
  // urllocaldb: "http://localhost:8015",
  // secretlocaldb: "G6XZ3I5V2E5o8w2mC1GqK-3z0p8P5PWaHshHyYT0A9I=",
};


export default config;
