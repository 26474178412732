import BaseModel from "./BaseModel";
import FlowPageModel from "./FlowPageModel";
import PageModel from "./PageModel";
class FlowModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.name = data?.name;

    this.flowpages =
      data?.flowpages.length > 0
        ? data?.flowpages.map((i) => new FlowPageModel(i))
        : [];

    this.redirectPageWhenFinished = data.redirectPageWhenFinished 
    ? new PageModel(data.redirectPageWhenFinished)
     : null
  }
}

export default FlowModel;
