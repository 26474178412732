import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { useHistory, withRouter } from "react-router-dom";

import CompanyLogoComponent from "../CompanyLogoComponent/CompanyLogoComponent";
import ButtonComponent from "../ButtonComponent/ButtonComponent";


import { Settings } from "../../utils/svgs/Settings"

import { Home } from "../../utils/svgs/Home"

/**
 * @Name: HeaderComponent
 * @Data: 2023
 * @Desc: Cabecalho do Sistema
 * @props:
 *
 */

const HeaderComponent = (props) => {
  // Recebe as propriedades

  const { companyStore, flowStore } = props

  const history = useHistory();

  const [path, setPath] = useState(history.location.pathname);

  

  function validateURL(url, blacklist) {
    for (var i = 0; i < blacklist.length; i++) {
      if (url.includes(blacklist[i])) {
        return false;
      }
    }
    return true;
  }

  const shouldRenderHomeButton = validateURL(path, [
    "/allocate-container",
    "/deallocate-container",
    "/reverse-container",
    "/config",
    "/correios-allocate"
  ]);
  const shouldRenderReverseButton = path == "/";

  useEffect(() => {
    // Usa listener do history para setar o caminho em hook
    // para validacao de renderizacao de botoes de acao
    const unlisten = history.listen((location) => {
      setPath(location.pathname);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const goToHomePage = () => {
    history.push("/");
    if (!validateURL(path, [
      "/select-organization",
      "/search-organization-final-node",
      "/select-customer",
    ]))
    flowStore.logEndSession();
  };

  const goToReversePage = () => {
    history.push("/qr-code-reverse");
  };

  return (
    <React.Fragment>
      <div className="HeaderComponent">
        <div className="logos-wrapper">
          <CompanyLogoComponent src={companyStore?.companyFeatures?.logo} />
          {companyStore?.companyFeatures?.secondaryLogo &&
            <CompanyLogoComponent src={companyStore?.companyFeatures?.secondaryLogo} />
          }
        </div>
        <div className="company-name">
          {companyStore?.companyFeatures?.onScreenName ?? ''}
        </div>
        <div className="action-buttons">
          {shouldRenderReverseButton && (
            <ButtonComponent state="primary" isAction onClick={goToReversePage}>
              {/* <img alt="" src={Settings} height={25} width={25} /> */}
              <Settings key={"reverse-icon"} color={companyStore?.companyFeatures?.secondaryColor} />
            </ButtonComponent>
          )}
          {shouldRenderHomeButton && (
            <ButtonComponent state="primary" isAction onClick={goToHomePage}>
              <Home key={"home-icon"} color={companyStore?.companyFeatures?.secondaryColor} />
            </ButtonComponent>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default inject(
  ({ lockerStore, companyStore, flowStore, userStore, allocationStore }) => ({
    companyStore,
    lockerStore,
    flowStore,
    userStore,
    allocationStore,
  })
)(withRouter(observer(HeaderComponent)));